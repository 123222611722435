import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Push Press 5×2\\@85% 1RM`}</p>
    <p>{`Band Face Pulls 5×10`}</p>
    <p>{`then,`}</p>
    <p>{`21-15-9 each:`}</p>
    <p>{`S2OH (135/95)(RX+155/105)`}</p>
    <p>{`Calorie Assault Bike`}</p>
    <p>{`K2E’s`}</p>
    <p>{`for time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      